.fc .fc-toolbar-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.fc .fc-daygrid-day-number {
    font-size: 1rem;
    color: #333;
}

.fc-event {
    background-color: red !important;
    color: white !important;
    font-size: 0.8rem;
}

.fc-day-today {
    background-color: #f2ecec !important;
}


.fc-daygrid-day.fc-day:hover {
    cursor: pointer;
}

.fc-event.event-available {
    background-color: red !important;
    border: none;
    text-align: center;
}

.fc-event.event-selected {
    background-color: blue !important;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 2px 4px;
    text-align: center;
}

.fc-event.event-available-2 {
    background-color: #f55139 !important;
    color: white !important;
    border: none;
    border-radius: 4px;
    padding: 2px 4px;
    text-align: center;
}

.fc-event.event-requested {
    background-color: orange !important;
    color: white !important;
    border: none;
    border-radius: 4px;
    padding: 2px 4px;
    text-align: center;
}

.fc-event.event-booked {
    background-color: red !important;
    color: white !important;
    border: none;
    border-radius: 4px;
    padding: 2px 4px;
    text-align: center;
}