/* styles.css */
/* For Webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 10px;
  /* Adjust width as needed */
  height: 7px;
  cursor: pointer;
  /* Adjust height as needed */
}

/* For Webkit Thumb */
::-webkit-scrollbar-thumb {
  background-color: #5D6577;
  /* Change color as needed */
  border-radius: 6px;
  cursor: pointer;
  /* Adjust border radius as needed */
}

/* For Firefox */
::-moz-scrollbar {
  width: 10px;
  /* Adjust width as needed */
  height: 7px;
  cursor: pointer;
  /* Adjust height as needed */
}

/* For Firefox Thumb */
::-moz-scrollbar-thumb {
  background-color: #5D6577;
  /* Change color as needed */
  border-radius: 20px;
  cursor: pointer;
  /* Adjust border radius as needed */
}

/* For IE and Edge */
/* Note: In IE and Edge, scrollbar colors cannot be directly customized. */