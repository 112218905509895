.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Hide the up and down arrows on input of type number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.custom-select .ant-select-selector {
  border: none !important;
}

.custom-select .ant-select-selector:focus,
.custom-select .ant-select-selector:focus-within {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

/* Hide the Scroll bar */
/* Hiding the scrollbar for Chrome, Safari, Edge */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hiding the scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

/* Make sure the content is still scrollable */
.hide-scrollbar {
  overflow: -moz-scrollbars-none;
  /* Older Firefox */
}