.custom-select .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/* Placeholder text color */
.custom-select .ant-select-selection-placeholder {
    color: black !important;
    font-weight: 480;
    font-size: 16px;
}

.custom-dropdown .ant-select-item {
    background-color: white !important;
    color: black !important;
}

.custom-dropdown .ant-select-item-option-selected {
    background-color: #f0f0f0 !important;
}

.custom-dropdown .ant-select-item-option-active {
    background-color: #e6f7ff !important;
}